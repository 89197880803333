export const environment = {
    production: false,
    msalConfig: {
        auth: {
            clientId: '24981299-8d52-4e15-b7b4-00cc1cdbd194',
            authority: 'https://login.microsoftonline.com/c886e28e-cc9f-41a2-b482-372bfdc52470',
            redirectUri: 'https://carehub.thehenley.com.au/',
            postLogoutRedirectUri: 'https://carehub.thehenley.com.au/'
        }
    },
    graphApi: {
        scopes: ['User.Read', 'GroupMember.Read.All'],
        uri: 'https://graph.microsoft.com/v1.0'
    },
    backend:
    {
        uri: 'https://carehub.thehenley.com.au/api'
    }
  };