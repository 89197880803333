import { Injectable } from '@angular/core';
import { Observable, from, of } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';
import { BaseApiService } from './api-base.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GraphService extends BaseApiService {

  is_member_of(objectId: string): Observable<boolean> {
    return this.getaccess<any>(`${environment.graphApi.uri}/me/memberOf/${objectId}`).pipe(
      map((res: any) => {
        if (res.error) {
          return false;
        } else if (res.id === objectId) {
          // console.log('Yes, user is a member of this group');
          return true;
        } else {
          return false;
        }
      }),
      catchError((error) => {
        // console.log('No, user is not a member of this group');
        return of(false);
      })
    );
  }
}
