// src/app/bucket/bucket.component.ts
import { Component, OnInit } from '@angular/core';
import { BucketService, Bucket } from 'src/app/services/bucket.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';


@Component({
  selector: 'app-bucket',
  templateUrl: './bucket.component.html',
  styleUrls: ['./bucket.component.css']
})
export class BucketComponent implements OnInit {
  buckets: Bucket[] = [];
  bucketForm: FormGroup;
  editing: boolean = false;
  currentBucketId: number | null = null;

  constructor(
    private bucketService: BucketService,
    private fb: FormBuilder
  ) {
    this.bucketForm = this.fb.group({
      Name: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    this.loadBuckets();
  }

  loadBuckets(): void {
    this.bucketService.getBuckets().subscribe(buckets => {
      this.buckets = buckets;
    });
  }

  onSubmit(): void {
    if (this.bucketForm.valid) {
      if (this.editing && this.currentBucketId !== null) {
        this.bucketService.updateBucket(this.currentBucketId, this.bucketForm.value).subscribe(() => {
          this.loadBuckets();
          this.resetForm();
        });
      } else {
        this.bucketService.createBucket(this.bucketForm.value).subscribe(() => {
          this.loadBuckets();
          this.resetForm();
        });
      }
    }
  }

  editBucket(bucket: Bucket): void {
    this.bucketForm.patchValue(bucket);
    this.editing = true;
    this.currentBucketId = bucket.ID;
  }

  deleteBucket(id: number): void {
    this.bucketService.deleteBucket(id).subscribe(() => {
      this.loadBuckets();
    });
  }

  resetForm(): void {
    this.bucketForm.reset();
    this.editing = false;
    this.currentBucketId = null;
  }
}