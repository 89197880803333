import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseApiService } from './api-base.service';

export interface Bucket {
  ID: number;
  Name: string;
}

export interface BucketActivity {
  ID: number,
  BucketID: number,
  ActivityID: number
}

export interface BucketRole {
  ID: number,
  BucketID: number,
  RoleID: number
}

export interface BucketShift {
  ID: number,
  BucketID: number,
  StaffID: number
}

@Injectable({
  providedIn: 'root'
})
export class BucketService extends BaseApiService {

  // Bucket CRUD
  getBuckets(): Observable<Bucket[]> {
    return this.get<Bucket[]>(`${this.apiUrl}/bucket/`);
  }

  getBucket(bucketId: number): Observable<Bucket> {
    return this.get<Bucket>(`${this.apiUrl}/bucket/${bucketId}`);
  }

  createBucket(bucket: Bucket): Observable<Bucket> {
    return this.post<Bucket>(`${this.apiUrl}/bucket/`, bucket);
  }

  updateBucket(bucketId: number, bucket: Bucket): Observable<Bucket> {
    return this.put<Bucket>(`${this.apiUrl}/bucket/${bucketId}`, bucket);
  }

  deleteBucket(bucketId: number): Observable<void> {
    return this.delete<void>(`${this.apiUrl}/bucket/${bucketId}`);
  }

  // BucketActivity CRUD
  getBucketActivities(): Observable<BucketActivity[]> {
    return this.get<BucketActivity[]>(`${this.apiUrl}/bucket-activities/`);
  }

  createBucketActivity(bucketActivity: BucketActivity): Observable<BucketActivity> {
    return this.post<BucketActivity>(`${this.apiUrl}/bucket-activity/`, bucketActivity);
  }

  updateBucketActivity(bucketActivityId: number, bucketActivity: BucketActivity): Observable<BucketActivity> {
    return this.put<BucketActivity>(`${this.apiUrl}/bucket-activity/${bucketActivityId}`, bucketActivity);
  }

  deleteBucketActivity(bucketActivityId: number): Observable<void> {
    return this.delete<void>(`${this.apiUrl}/bucket-activity/${bucketActivityId}`);
  }

  // BucketRole CRUD
  getBucketRoles(): Observable<BucketRole[]> {
    return this.get<BucketRole[]>(`${this.apiUrl}/bucket-roles/`);
  }

  createBucketRole(bucketRole: BucketRole): Observable<BucketRole> {
    return this.post<BucketRole>(`${this.apiUrl}/bucket-role/`, bucketRole);
  }

  updateBucketRole(bucketRoleId: number, bucketRole: BucketRole): Observable<BucketRole> {
    return this.put<BucketRole>(`${this.apiUrl}/bucket-role/${bucketRoleId}`, bucketRole);
  }

  deleteBucketRole(bucketRoleId: number): Observable<void> {
    return this.delete<void>(`${this.apiUrl}/bucket-role/${bucketRoleId}`);
  }

  // BucketShift CRUD
  getBucketShifts(bucketId?: number): Observable<BucketShift[]> {
    if (bucketId) {
      return this.get<BucketShift[]>(`${this.apiUrl}/bucket-shifts/${bucketId}`);
    }
    return this.get<BucketShift[]>(`${this.apiUrl}/bucket-shifts/`);
  }

  createBucketShift(bucketShift: BucketShift): Observable<BucketShift> {
    return this.post<BucketShift>(`${this.apiUrl}/bucket-shift/`, bucketShift);
  }

  updateBucketShift(bucketShiftId: number, bucketShift: BucketShift): Observable<BucketShift> {
    return this.put<BucketShift>(`${this.apiUrl}/bucket-shift/${bucketShiftId}`, bucketShift);
  }

  deleteBucketShift(bucketShiftId: number): Observable<void> {
    return this.delete<void>(`${this.apiUrl}/bucket-shift/${bucketShiftId}`);
  }
}
