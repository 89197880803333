<div class="container">
    <h2>Categories</h2>
  
    <form [formGroup]="bucketForm" (ngSubmit)="onSubmit()">
      <div class="form-group">
        <label for="Name">Category Name</label>
        <input type="text" id="Name" formControlName="Name" class="form-control" placeholder="Enter category name">
      </div>
      <button type="submit" class="btn btn-primary">{{ editing ? 'Update' : 'Create' }}</button>
      <button type="button" class="btn btn-secondary" (click)="resetForm()">Reset</button>
    </form>
  
    <hr>
  
    <ul class="list-group">
      <li *ngFor="let bucket of buckets" class="list-group-item">
        {{ bucket.Name }}
        <button class="btn btn-warning btn-sm" (click)="editBucket(bucket)">Edit</button>
        <button class="btn btn-danger btn-sm" (click)="deleteBucket(bucket.ID)">Delete</button>
      </li>
    </ul>
  </div>