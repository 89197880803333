// Core
import { NgModule } from '@angular/core';
import { environment } from 'src/environments/environment';

// Modules
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatNativeDateModule } from '@angular/material/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';

import { AppRoutingModule } from './app-routing.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { IPublicClientApplication, PublicClientApplication, InteractionType, BrowserCacheLocation, LogLevel } from '@azure/msal-browser';
import { MsalGuard, MsalInterceptor, MsalBroadcastService, MsalInterceptorConfiguration, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalGuardConfiguration, MsalRedirectComponent } from '@azure/msal-angular';

// Services
import { ActivityService } from './services/activity.service';
import { BaseApiService } from './services/api-base.service';
import { BucketService } from './services/bucket.service';
import { DateService } from './services/date.service';
import { EventService } from './services/event.service';
import { HelperService } from './services/helper.service';
import { StaffService } from './services/staff.service';

// Components
import { AppComponent } from './app.component';
import { FailedComponent } from './pages/failed/failed.component';
import { HomeComponent } from './home/home.component';
import { ProfileComponent } from './pages/profile/profile.component';

import { ActivityComponent } from './components/base_elements/activity/activity.component';
import { BucketComponent } from './components/base_elements/bucket/bucket.component';
import { BucketDropdownComponent } from './components/ui_elements/bucket-dropdown/bucket-dropdown.component';
import { BucketStaffDropdownComponent } from './components/ui_elements/bucket-staff-dropdown/bucket-staff-dropdown.component';
import { PublishComponent } from './components/ui_elements/publish/publish.component';
import { ReallocateComponent } from './components/reallocate/reallocate.component';
import { RoleComponent } from './components/base_elements/role/role.component';
import { RosterTimelineComponent } from './components/roster-timeline/roster-timeline.component';
import { ShiftComponent } from './components/base_elements/shift/shift.component';
import { StaffComponent } from './components/base_elements/staff/staff.component';
import { TodoComponent } from './pages/todo/todo.component';
import { CustomLightboxComponent } from './components/ui_elements/custom-lightbox/custom-lightbox.component';
import { ConfirmationDialogComponent } from './components/ui_elements/confirmation-dialog/confirmation-dialog.component';

// MSAL Configuration
export function loggerCallback(logLevel: LogLevel, message: string) {
  // console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.msalConfig.auth.clientId,
      authority: environment.msalConfig.auth.authority,
      redirectUri: environment.msalConfig.auth.redirectUri,
      postLogoutRedirectUri: environment.msalConfig.auth.postLogoutRedirectUri
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage
    },
    system: {
      allowNativeBroker: false, // Disables WAM Broker
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    }
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set(environment.backend.uri, []);
  protectedResourceMap.set(environment.graphApi.uri, environment.graphApi.scopes);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return { 
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [...environment.graphApi.scopes]
    },
    loginFailedRoute: '/login-failed'
  };
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ProfileComponent,
    FailedComponent,

    ActivityComponent,
    BucketComponent,
    BucketDropdownComponent,
    RoleComponent,
    ShiftComponent,
    StaffComponent,
    RosterTimelineComponent,
    BucketStaffDropdownComponent,
    TodoComponent,
    ReallocateComponent,
    PublishComponent,
    CustomLightboxComponent,
    ConfirmationDialogComponent

  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule, 

    DragDropModule,
    FormsModule,
    ReactiveFormsModule,

    MatAutocompleteModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatSnackBarModule,
    MatTableModule,
    MatToolbarModule,

    AppRoutingModule,
    HttpClientModule,
    MsalModule,
  ],
  providers: [
    ActivityService,
    BaseApiService,
    BucketService,
    DateService,
    EventService,
    HelperService,
    StaffService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
